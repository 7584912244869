import React, { useState } from "react";
import {
    Box,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiMenu } from "react-icons/fi";
import DarkMode from "./DarkMode";
// @ts-ignore
import frFlag from "../../assets/drapeaux/fr.svg";
// @ts-ignore
import usFlag from "../../assets/drapeaux/gb.svg";
// @ts-ignore
import esFlag from "../../assets/drapeaux/es.svg";
import HomeButton from "./HomeButton";
import { useLocation } from 'react-router-dom';
import Pform from "../form";

const NavBar = () => {
    useLocation();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const changeLanguage = (lng: React.SetStateAction<string> | undefined) => {
        // @ts-ignore
        i18n.changeLanguage(lng);
        // @ts-ignore
        setSelectedLanguage(lng);
    };

    const languageOptions = [
        { code: 'fr', label: "Français" },
        { code: 'en', label: "Anglais" },
        { code: 'es', label: 'Espagnol' },
    ];

    const flagImageForSelectedLanguage = () => {
        switch (selectedLanguage) {
            case 'fr':
                return frFlag;
            case 'en':
                return usFlag;
            case 'es':
                return esFlag;
            default:
                return frFlag;
        }
    };

    const [showLoginModal, setShowLoginModal] = useState(false);

    const handleCloseLoginModal = () => {
        setShowLoginModal(false);
    };

    const selectedLanguageOption = languageOptions.find(
        (option) => option.code === selectedLanguage
    );

    const selectedLanguageLabel = selectedLanguageOption ? selectedLanguageOption.label : '';

    // @ts-ignore
    return (
        <Box
            bg={useColorModeValue("rgba(255, 255, 255, 0.9)", "rgba(0, 0, 0, 0.9)")}
            px={4}
            position="fixed"
            width="100%"
            zIndex={9999}
            id="Home"
        >
            <Flex
                h={16}
                alignItems="center"
                justifyContent="space-between"
                direction={{ base: "column", md: "row" }}
            >
                <Flex
                    display={{ base: "none", md: "flex" }}
                    alignItems="center"
                    justifyContent="center"
                    width="160%"
                >
                    <HomeButton />
                    <a href="#Services" style={{ display: "flex", alignItems: "center" }}>
                        <Text
                            color={useColorModeValue("#003552", "blue.200")}
                            fontSize="xl"
                            mx={5}
                            _hover={{ textDecoration: "underline" }}
                        >
                            {t("navBarServices")}
                        </Text>
                    </a>
                    <a href="#Produits">
                        <Text
                            _hover={{ textDecoration: "underline" }}
                            color={useColorModeValue("#003552", "blue.200")}
                            fontSize="xl"
                            mx={5}
                        >
                            {t("navBarProduits")}
                        </Text>
                    </a>
                    <a href="#aboutUs">
                        <Text
                            _hover={{ textDecoration: "underline" }}
                            color={useColorModeValue("#003552", "blue.200")}
                            fontSize="xl"
                            mx={5}
                        >
                            {t("navBaraboutUs")}
                        </Text>
                    </a>
                    <a href="#contact">
                        <Text
                            _hover={{ textDecoration: "underline" }}
                            color={useColorModeValue("#003552", "blue.200")}
                            fontSize="xl"
                            mx={5}
                        >
                            {t("navBarcontact")}
                        </Text>
                    </a>
                </Flex>

                <div style={{ display: "flex", width: "100%", margin:"auto" }}>

                    <Flex
                        display={{ base: "flex", md: "none" }}
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Menu>
                            <MenuButton>
                                <FiMenu size={24} />
                            </MenuButton>
                            <MenuList>
                                <MenuItem>
                                    <a href="#Services">{t("navBarServices")}</a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#Produits">{t("navBarProduits")}</a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#aboutUs">{t("navBaraboutUs")}</a>
                                </MenuItem>
                                <MenuItem>
                                    <a href="#contact">{t("navBarcontact")}</a>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                    <Flex
                        display={{ base: "flex", md: "none" }}
                        alignItems="center"
                        justifyContent="center"
                        marginLeft="35%"
                    >
                        <HomeButton />
                    </Flex>
                    <Flex
                        display={{ base: "flex", md: "flex" }}
                        alignItems="center"
                        justifyContent="flex-end"
                        width="100%"
                    >
                        <Menu >
                            <MenuButton>
                                <Flex alignItems="center">
                                    <Image
                                        src={flagImageForSelectedLanguage()}
                                        alt={selectedLanguageLabel + " Flag"}
                                        boxSize={6}
                                    />
                                    <FiChevronDown size={18} />
                                </Flex>
                            </MenuButton>
                            <MenuList>
                                {languageOptions.map((option) => (
                                    <MenuItem
                                        key={option.code}
                                        onClick={() => changeLanguage(option.code)}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                        <Box ml={4} />
                        <DarkMode />
                    </Flex>
                </div>
                <Modal isOpen={showLoginModal} onClose={handleCloseLoginModal}>
                    <ModalOverlay
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(4px)",
                        }}
                    />
                    <ModalContent
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                        }}
                    >
                        <ModalBody>
                            <Pform />
                        </ModalBody>
                    </ModalContent>
                </Modal>

            </Flex>
        </Box>
    );
};

export default NavBar;

