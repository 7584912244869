import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const Zebi = () => {
    const { t } = useTranslation();

    return (
        <Box
            minHeight="75vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            position="relative"
        >
            <video
                autoPlay
                loop
                muted
                style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}
            >
                <source src={require('../assets/video.mp4')} type="video/mp4" />
            </video>
        </Box>
    );
};

export default Zebi;
