import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Box, Center, Divider, Text} from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
// @ts-ignore
import construction1 from "../assets/photo_bat/construction1.jpg";
// @ts-ignore
import construction2 from "../assets/photo_bat/construction2.jpg";
// @ts-ignore
import construction3 from "../assets/photo_bat/construction3.jpg";
// @ts-ignore
import construction5 from "../assets/photo_bat/construction5.jpg";
// @ts-ignore
import construction6 from "../assets/photo_bat/construction6.jpg";
// @ts-ignore
import construction7 from "../assets/photo_bat/construction7.jpg";
// @ts-ignore
import construction8 from "../assets/photo_bat/construction8.jpg";
// @ts-ignore
import construction9 from "../assets/photo_bat/construction9.jpg";


import {  useTranslation } from 'react-i18next';
import React from "react";


const Realisations = () => {
    const { t} = useTranslation();
    return (
        <Box id ="Produits" textAlign="center" bg="#003552" color="white" py={8}>
            <Text fontSize={{ base: "4xl", md: "6xl" }} color="white">
                {t('realisationTitre')}
            </Text>
            <Center>
                <Divider orientation='horizontal' w="50%" />
            </Center>

            <Box maxW="1000px" mx="auto" marginY={4}
                 boxShadow="md"  // Ajouter une ombre de taille moyenne
            >
                <Box borderWidth="1px" borderRadius="md" overflow="hidden" margin={4}>
                    <Carousel
                        showArrows={true}
                        infiniteLoop={true}
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={true}
                        interval={2000}
                    >
                        <Box>
                            <Image src={construction1} alt="" boxSize="100%" objectFit="cover" />
                        </Box>
                        <Box>
                            <Image src={construction2} alt="" boxSize="100%" objectFit="cover" />
                        </Box>
                        <Box>
                            <Image src={construction3} alt="" boxSize="100%" objectFit="cover" />
                        </Box>
                        <Box>
                            <Image src={construction5} alt="" boxSize="100%" objectFit="cover" />
                        </Box>
                        <Box>
                            <Image src={construction6} alt="" boxSize="100%" objectFit="cover" />
                        </Box>
                        <Box>
                            <Image src={construction7} alt="" boxSize="100%" objectFit="cover" />
                        </Box>
                        <Box>
                            <Image src={construction8} alt="" boxSize="100%" objectFit="cover" />
                        </Box>
                        <Box>
                            <Image src={construction9} alt="" boxSize="100%" objectFit="cover" />
                        </Box>
                    </Carousel>
                </Box>
            </Box>
        </Box>
    );
}

export default Realisations;
