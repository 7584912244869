import React, {} from "react";
import {
    Box,
    Text,
    Flex,
    Divider,
    useColorModeValue,
    Center,

} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';


const AboutUs = () => {
    const { t} = useTranslation();


    return (
        <Flex id="aboutUs" direction="column" align="center" justify="center" >
            <Box textAlign="center" bg={useColorModeValue("#FFFFFF", "#1A202C")} p={4} rounded="md" maxW="1000px" mx="auto">
                <Text fontSize={{base: '3xl', md: '6xl'}} textAlign="center" color={useColorModeValue("#003552", "blue.200")}>
                    {t('aboutUsTitle')}
                </Text>
                <Divider />
                <Center  height="50px">
                    <Divider orientation="vertical" variant="thick" size="xl" />
                </Center>
                <Text fontSize={{base: '1xs', md: 'lg'}} color={useColorModeValue('#003552', 'blue.200')} mt={4}>
                    {t('aboutUsText')}
                </Text>
                <Center height="100px">
                    <Divider />
                </Center>

            </Box>
        </Flex>
    );
};

export default AboutUs;
