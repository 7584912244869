import {
    Box,
    Flex,
    Image,
    Text,
    Link,
    useColorModeValue,
    Stack,
    Divider,
    Center,
    Button,
    Modal,
    ModalOverlay, ModalContent, ModalBody
} from "@chakra-ui/react";
import Pform from "./form";
import { useTranslation } from 'react-i18next';
// @ts-ignore
import mail from "../assets/mail.png";
// @ts-ignore
import phone from "../assets/phone.png";
// @ts-ignore
import adresse from "../assets/adresse.png";
import React, { useState } from "react";

const Contact = () => {
    const { t} = useTranslation();
    const shouldDisplayDivider = typeof window !== 'undefined' && window.innerWidth >= 768;
    const [showLoginModal, setShowLoginModal] = useState(false);
    const handleShowLoginModal = () => {
        setShowLoginModal(true);
    };
    const handleCloseLoginModal = () => {
        setShowLoginModal(false);
    };
    return (
        <Box id="contact" textAlign="center" mb={8} py={{ base: 8, md: 12 }} px={4} bg={useColorModeValue("white", "#1A202C")}>
            <Text fontSize={{ base: "4xl", md: "6xl" }} textAlign="center" color={useColorModeValue("#003552", "blue.200")}>
                {t('contactTitre')}
            </Text>
            <Box ml={10} />
            <Flex justify="center" flexWrap="wrap">
                <Box textAlign="center" mx={4} my={4} flex="1 0 300px">
                    <Image src={phone} alt="Image 1" style={{ margin: "0 auto" }} boxSize='100px'/>
                    <Box ml={10} />
                    <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                        {t('contactTet')}
                    </Text>
                    <Box ml={10} />
                    <Link fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                        {t('contactTelnum')}
                    </Link>
                </Box>
                {shouldDisplayDivider && (
                    <Stack direction="row" h="200px" p={4}>
                        <Divider orientation="vertical" order={2} />
                    </Stack>
                )}
                <Box textAlign="center" mx={4} my={4} flex="1 0 300px">
                    <Image src={adresse} alt="Image 3" style={{ margin: "0 auto" }} boxSize='100px'/>
                    <Box ml={10} />
                    <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                        {t('contactadress')}
                    </Text>
                    <Box ml={10} />
                    <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                        {t('contactadressnum')}
                    </Text>
                </Box>
                {shouldDisplayDivider && (
                    <Stack direction="row" h="200px" p={4}>
                        <Divider orientation="vertical" order={2} />
                    </Stack>
                )}
                <Box textAlign="center" mx={4} my={4} flex="1 0 300px">
                    <Box textAlign="center" flexDirection="column">
                        <Image src={mail} alt="" style={{ margin: "0 auto" }} boxSize='100px'/>
                        <Box ml={10} />
                        <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                            {t('contactmail')}
                        </Text>
                        <Box ml={10} />
                        <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                            {t('contactmailnum')}
                        </Text>
                    </Box>
                </Box>

            </Flex>
            <Center marginY="5%">
                <a onClick={handleShowLoginModal}>
                    <Button colorScheme="teal" variant="outline" color={useColorModeValue('#003552', 'blue.200')} mt={8}>
                        {t('navBarcontact')}
                    </Button>
                </a>
            </Center>
            <Modal isOpen={showLoginModal} onClose={handleCloseLoginModal}>
                <ModalOverlay
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        backdropFilter: "blur(4px)",
                    }}
                />
                <ModalContent
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    }}
                >
                    <ModalBody>
                        <Pform />
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Divider/>
        </Box>
    );
};

export default Contact;




