import {Box, Text, Image, useBreakpointValue} from "@chakra-ui/react";
// @ts-ignore
import map from "../assets/map.png";

import {useTranslation} from 'react-i18next';

const Implantations = () => {
  const isSmallScreen = useBreakpointValue({base: true, md: false});
  const {t} = useTranslation();
  return (
    <Box textAlign="center" bg="#003552" color="white" py={8} px={4}>
      <Text fontSize={{base: "4xl", md: "6xl"}} textAlign="center" my={4}>
        {t('ImplantationsTitre')}
      </Text>
      <Box mx={"auto"} my={4} width={"90%"} mt={4} mb={4}>
        {!isSmallScreen && <Image src={map} alt="Carte des implantations" mx="auto" w="100%" objectFit="contain"/>}
        {isSmallScreen && (
          <Box>
            <Box borderWidth="3px"
                 borderColor="white"
                 borderRadius="md"
                 marginBottom="4">

              <Image
                src={require("../assets/map_europe.png")}
                alt="Carte des implantations europe"
                mx="auto"
                w="100%"
                objectFit="contain"
              />
            </Box>
            <Box borderWidth="3px"
                 borderColor="white"
                 borderRadius="md"
                 marginBottom="4">
              <Image
                src={require("../assets/map_asie.png")}
                alt="Carte des implantations europe"
                mx="auto"
                w="100%"
                objectFit="contain"
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Implantations;
