import {Box, Flex, Center, Image, Text, useColorModeValue, Divider, Stack} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import construction from "../assets/construction.png";
// @ts-ignore
import boutique from "../assets/boutique.png";
import React from "react";

const Activity = () => {
    const { t} = useTranslation();
    const shouldDisplayDivider = typeof window !== "undefined" && window.innerWidth >= 768;
    // @ts-ignore
    return (
        <Flex
            id="Services"
            direction="column"
            align="center"
            justify="center"
            py={8}
            bg={useColorModeValue("#FFFFFF", "#1A202C")}
        >
            <Center>
                <Text fontSize={{ base: "4xl", md: "6xl" }} textAlign="center" color={useColorModeValue("#003552", "blue.200")} py={8}>
                    {t('activitiTitre')}
                </Text>
            </Center>
            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="center"
                width="100%"
                maxWidth="1200px"
                px={4}
                border="1px solid #e2e8f0"
                boxShadow="md"
            >
                <Box width={{ base: "100%", md: "50%" }} my={{ base: 4, md: 0 }} textAlign="center">
                    <Image src={construction} alt="Image 1" boxSize="100px" style={{ margin: "0 auto" }}/>
                    <Text fontSize={{ base: "xl", md: "2xl" }} color={useColorModeValue('#003552', 'blue.200')} mt={4}>
                        {t('activitiTitrecons')}
                    </Text>
                    <Text align="center" color={useColorModeValue('#003552', 'blue.200')} mt={2}>
                        <p>{t('activitiTextecons')}</p>
                        <p>{t('activitiTextecons1')}</p>
                        <p>{t('activitiTextecons2')}</p>
                        <p>{t('activitiTextecons3')}</p>

                    </Text>
                </Box>
                {shouldDisplayDivider && (
                    <Stack direction="row" h="300px" p={4}>
                        <Divider orientation="vertical" order={2} />
                    </Stack>
                )}
                <Box width={{ base: "100%", md: "50%" }} my={{ base: 4, md: 0 }} textAlign="center">
                    <Image src={boutique} alt="Image 2" boxSize="100px" style={{ margin: "0 auto" }}/>
                    <Text fontSize={{ base: "xl", md: "2xl" }} color={useColorModeValue('#003552', 'blue.200')} mt={4}>
                        {t('activitiTitrevent')}
                    </Text>
                    <Text align="center" color={useColorModeValue('#003552', 'blue.200')} mt={2}>
                        {t('activitiTextevent')}
                    </Text>
                </Box>

            </Flex>
        </Flex>
    );
};

export default Activity;
