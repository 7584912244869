import React, { useRef, useState } from "react";
import {
    Box,
    Button,
    Center,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Alert,
    AlertIcon,
    AlertTitle,
    useColorModeValue,
} from "@chakra-ui/react";
import emailjs from "emailjs-com";
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t} = useTranslation();
    const form = useRef(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const sendEmail = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        // @ts-ignore
        emailjs.sendForm("service_u2ah1lk", "template_c9r2czc", form.current, "O263cZp3-BIPmfLIU")
            .then(
                (result) => {
                    console.log(result.text);
                    setShowSuccessAlert(true);
                    setSuccessMessage(t('formmesgV'));
                    setErrorMessage("");
                    window.location.reload();
                },
                (error) => {
                    console.log(error.text);
                    setSuccessMessage("");
                    setErrorMessage(t('formmesgF'));
                }
            );
    };

    return (
        <Center>
            <Box width="400px" mx="auto" mt={8} p={4} bg={useColorModeValue("gray.100", "gray.700")} borderRadius="md"
                 border="1px solid #e2e8f0"
                 boxShadow="md">
                <form onSubmit={sendEmail} ref={form}>
                    {successMessage && (
                        <Alert status="success" mt={4}>
                            <AlertIcon />
                            <AlertTitle>{successMessage}</AlertTitle>
                        </Alert>
                    )}
                    {errorMessage && (
                        <Alert status="error" mt={4}>
                            <AlertIcon />
                            <AlertTitle>{errorMessage}</AlertTitle>
                        </Alert>
                    )}
                    <FormControl>
                        <FormLabel>{t('formNom')}</FormLabel>
                        <Input id="Nom" name="name" type="text" placeholder= {t('formNomex')} required />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>{t('formPrenom')}</FormLabel>
                        <Input id="Prenom" name="prenom" type="text" placeholder={t('formPrenomex')} required />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>{t('formAdresse')}</FormLabel>
                        <Input id="email" name="email" type="email" placeholder={t('formAdresseex')} required />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>{t('formTel')}</FormLabel>
                        <Input id="Telephone" name="tel" type="tel" placeholder={t('formteex')} required />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>{t('formBut')}</FormLabel>
                        <Textarea id="message" name="message" placeholder={t('formButex')} required />
                    </FormControl>
                    <Box ml={4} />
                    <Center margin="2%">
                        <Button colorScheme="teal" variant="outline" color={useColorModeValue('#003552', 'blue.200')} type="submit">
                            {t('formEnvoyer')}
                        </Button>
                    </Center>

                </form>

            </Box>
        </Center>
    );
};

export default ContactForm;