import React from "react";
import { Box, Button, Flex, Image, Stack, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
// @ts-ignore
import logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';

export default function HomeButton() {
    const handleRefresh = () => {
        window.location.reload();
    };
    const { t} = useTranslation();
    return (
        <Box ml={-40}>
            <Flex alignItems="center">
                <Link to={"/"} style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
                    <Button onClick={handleRefresh} variant="link" _hover={{ textDecoration: "underline" }}>
                        <Image src={logo} alt="Dan Abramov" boxSize={8} mr={2} />
                        <Text color={useColorModeValue("#003552", "blue.200")} fontSize="xl">{t('Titre')}</Text>
                    </Button>
                </Link>
            </Flex>
        </Box>

    );
}

