import {Box, Text, Flex, useColorModeValue, Divider, Stack} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
// @ts-ignore
import batiment from "../assets/construction.mp4";
// @ts-ignore
import solar from "../assets/solar.mp4";
import React from "react";

const Solutions = () => {
    const { t} = useTranslation();
    const shouldDisplayDivider = typeof window !== "undefined" && window.innerWidth >= 768;
    const boxStyle = shouldDisplayDivider
        ? {
            border: "1px solid #e2e8f0",
            boxShadow: "md",
            p: 4,
        }
        : {};
    return (
        <Flex
            direction="column"
            align="center"
            justify="flex-start"
            bg={useColorModeValue("#003552", "#003552")}
            px={8}
            py={12}
        >
            <Text textAlign="center" fontSize={{ base: "4xl", md: "6xl" }} color="white">
                {t('solutionTitre')}
            </Text>
            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="center"
                my={8}
                px={4}
                width="100%"
                maxWidth="1200px"
                {...boxStyle}
            >
                <Box textAlign="center" py={8} width={{ base: "100%", md: "50%" }} my={{ base: 4, md: 4 }}>
                    <Text fontSize={{ base: "2xl", md: "xl" }} color="white" fontWeight="bold">
                        {t('solutionTitre1')}
                    </Text>
                    <Text color="white" mt={4} fontSize={{ base: "md", md: "lg" }}>
                        {t('solutionTexte')}
                    </Text>
                </Box>
                {shouldDisplayDivider && (
                    <Stack direction="row" h="100px" p={4}>
                        <Divider orientation="horizontal" order={2} />
                    </Stack>
                )}
                <Box ml={10} />
                <Box width={{ base: "100%", md: "50%" }} my={{ base: 4, md: 4 }}>
                    <video autoPlay loop muted width="100%" height="auto">
                        <source src={solar} type="video/mp4" />
                    </video>
                </Box>
            </Flex>

            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="center"
                my={8}
                px={4}
                width="100%"
                maxWidth="1200px"
                {...boxStyle}
            >
                <Box width={{ base: "100%", md: "50%" }}>
                    <video autoPlay loop muted width="100%" height="auto">
                        <source src={batiment} type="video/mp4" />
                    </video>
                </Box>
                <Box ml={10} />
                {shouldDisplayDivider && (
                    <Stack direction="row" h="300px" p={4}>
                        <Divider orientation="horizontal" order={2} />
                    </Stack>
                )}
                <Box textAlign="center" py={8} width={{ base: "100%", md: "50%" }}
                    >
                    <Text fontSize={{ base: "2xl", md: "xl" }} color="white" fontWeight="bold">
                        {t('solutionTitre2')}
                    </Text>
                    <Text color="white" mt={4} fontSize={{ base: "md", md: "lg" }}>
                        {t('solutionTexte2')}
                    </Text>

                </Box>
            </Flex>
        </Flex>
    );
};

export default Solutions;
