import {Box, Text, Flex, Link} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import React from "react";

const MapSite = () => {
    const boxStyle = {
        flex: "1 0 300px",
        mx: 4,
        my: 8,
        marginBottom: '20px',
    };
    const textStyle = {
        color: "white",
        fontSize: ["xs", "sm", "md"],
    };

    const titleStyle = {
        color: "white",
        fontSize: ["lg", "xl", "2xl"],
        marginBottom: "8px",
    };
    const { t} = useTranslation();
    return (
        <Box textAlign="center" padding="50px" bg="#1A202C" minHeight={300}>
            <Flex justify="center" flexWrap="wrap">
                <Box style={boxStyle}>
                    <Text {...titleStyle}>{t('MapsitEntreprise')}</Text>
                    <Text {...textStyle}>{t('MapsitHMP')}</Text>
                    <Text {...textStyle}>{t('MapsitText')}</Text>

                </Box>
                <Box style={boxStyle}>
                    <Text {...titleStyle}>  {t('Mapsitcontact')}</Text>
                    <Text {...textStyle}>{t('Mapsitmailnum')}</Text>
                    <Text {...textStyle}>{t('MapsitTelnum')}</Text>
                </Box>
                <Box style={boxStyle}>
                    <Box textAlign="center" flexDirection="column">
                        <Text {...titleStyle}>{t("MapsitPDS")}</Text>
                        <Link href="/">
                            <Text {...textStyle}>{t('MapsitAccueil')}</Text>
                        </Link>
                        <Link href="#Services">
                            <Text {...textStyle}>{t('MapsitServices')}</Text>
                        </Link>
                        <Link href="#contact">
                            <Text {...textStyle}> {t('MapsitProduits')}</Text>
                        </Link>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default MapSite;
